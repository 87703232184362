<script lang="ts" setup>
import { defineEmits, defineProps, withDefaults } from 'vue'
import { windowFreeze, windowUnfreeze } from '~/utils/freeze'

interface PopupProps {
  modelValue: boolean
  title?: string
  centered?: boolean
  popupWrapperClass?: string
  mainWrapperClass?: string
  noPadding?: boolean
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | null
  unstyled?: boolean
}

const props = withDefaults(defineProps<PopupProps>(), {
  centered: true,
  size: '2xl',
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'close'): void
}>()

const closePopup = () => {
  emit('update:modelValue', false)
  emit('close')
}

const style = computed(() => ({
  mainWrapper: [
    'fixed left-0 top-0 right-0 bottom-0 z-[100] overflow-y-auto overscroll-none overflow-scrolling-touch flex',
    {
      'place-items-center': props.centered,
      'p-5 md:p-14': !props.noPadding,
    },
  ],
  popupWrapper: [
    'relative pointer-events-auto',
    {
      'p-5 lg:p-7 rounded-2xl bg-t-bg theme-green-light dark:theme-blue text-t-text-light': !props.unstyled,
      'my-auto': props.centered,
      'w-full mx-auto': props.size !== null,
      'max-w-sm': props.size === 'sm',
      'max-w-md': props.size === 'md',
      'max-w-lg': props.size === 'lg',
      'max-w-xl': props.size === 'xl',
      'max-w-2xl': props.size === '2xl',
      'max-w-3xl': props.size === '3xl',
      'max-w-4xl': props.size === '4xl',
      'max-w-5xl': props.size === '5xl',
    },
  ],
  closeButton: [
    'absolute top-5 right-5 w-8 h-8 text-t-text border border-t-border flex items-center justify-center rounded-lg transition-colors hover:border-opacity-100',
    'md:top-0 md:left-full md:right-auto md:ml-3 md:border-white md:border-opacity-20 md:text-white',
  ],
  closeIcon: 'w-6 h-6',
}))

watch(() => props.modelValue, (value) => {
  if (value) {
    setTimeout(windowFreeze)
  }
  else {
    const popups = document.querySelectorAll('.common-popup')
    if (popups.length === 1)
      windowUnfreeze()
  }
})
</script>

<template>
  <Teleport to="body">
    <Transition name="fade">
      <CommonOverlay v-if="modelValue" class="z-[100]" @click="closePopup" />
    </Transition>
    <Transition name="fade-from-bottom">
      <div v-if="modelValue" :class="[style.mainWrapper, mainWrapperClass]" class="common-popup">
        <div :class="[style.popupWrapper, popupWrapperClass]">
          <h2 v-if="title" class="text-2xl lg:text-5xl heading text-t-text mb-4 lg:mb-5 lg-max:pr-11">
            {{ title }}
          </h2>
          <slot :close-popup="closePopup" />
          <button type="button" :class="style.closeButton" @click="closePopup">
            <Icon name="ri:close-line" :class="style.closeIcon" />
          </button>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>
